.course-card {
	padding: 0.8rem;

	&:not(:last-child) {
		border-bottom: 0.2rem solid var(--jet);
	}

	&__editable-course-info {
		display: flex;
		align-items: center;
	}

	&__editable-department {
		width: 30%;
	}

	&__editable-course {
		margin: 0 0.8rem;
		width: calc(70% - 2.6rem);
	}

	&__editable-professor {
		margin: 0.8rem 0;
		width: 70%;
	}

	&__editable-feelings {
		margin-top: 0.4rem;
		width: 100%;
		height: 5rem;
		resize: none;
		background-color: var(--dough);
		border: 0.2rem solid var(--jet);
		border-radius: 3px;
		color: var(--jet);
		font-size: 1rem;
		font-family: 'Lexend', sans-serif;
		transition: background-color 150ms ease;

		&:hover {
			background-color: var(--dough-dark1);
		}

		&::placeholder {
			color: var(--jet);
		}

		&:focus {
			background-color: var(--dough-dark2);
			&::placeholder {
				opacity: 0;
			}
		}
	}

	&__viewable-feelings {
		white-space: pre-line;
	}

	.icon-button--remove-course {
		width: 2.6rem;
		height: 2.6rem;
	}
}
