.header {
	display: grid;
	grid-template: auto auto / 1fr;
	gap: 2rem;
	background-color: var(--dough);

	@media (min-width: 700px) {
		grid-template: auto / 1fr 2fr;
		gap: 3rem;
	}

	&__topbar {
		display: flex;
		gap: 1rem;
	}

	&__icon {
		height: 2.6rem;
	}

	&__navbar {
		display: flex;
		justify-content: center;
		gap: 1.5rem;
	}
}
