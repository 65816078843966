.search-input {
	position: relative;
	width: 100%;

	& .text-input {
		padding-right: 2.6rem;
	}

	& .icon-button {
		position: absolute;
		top: 0.4rem;
		right: 0.4rem;
	}
}
