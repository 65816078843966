.nav-button {
	display: flex;
	align-items: center;
	gap: 0.2rem;
	font-weight: 300;
	transition: font-weight 50ms ease;

	&:hover {
		font-weight: 400;
	}

	&__breadcrumb {
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 2px;
		background-color: var(--dough);
	}

	&__text {
		margin: 0 0.2rem;
		font-size: 1.25rem;
		font-weight: inherit;
		text-align: center;
		color: var(--crust);
	}

	&--custom-active {
		& .nav-button__breadcrumb {
			background-color: var(--crust);
		}

		& .nav-button__text {
			font-weight: 700;
		}
	}
}
