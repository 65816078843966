.article-container {
	display: grid;
	grid-template: auto auto / 1fr;
	gap: 2rem;
	margin-top: 2rem;

	@media (min-width: 700px) {
		grid-template: 1fr / 1fr 2fr;
		margin-top: 3rem;
	}

	&--dense {
		gap: 0.75rem;
	}
}
