.full-screen-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 4rem 2rem;
	background-color: var(--tan);

	&__container {
		width: min(100%, 20rem);
	}
}
