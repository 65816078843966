html {
	box-sizing: border-box;
	font-size: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Lexend', sans-serif;
	background-color: var(--dough);
	color: var(--jet);
}

button {
	border-radius: 5px;
	border: none;
	font-family: inherit;
	font-size: 1.25rem;
	cursor: pointer;
}

input {
	font-family: inherit;
}

input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}
