.page-container {
	display: flex;
	justify-content: center;
	height: 100vh;
	overflow-y: auto;
	padding: 2rem;
	background-color: var(--dough);

	@media (min-width: 700px) {
		padding: 3rem;
	}

	&__div {
		width: min(100%, 75rem);
	}
}
