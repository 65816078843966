:root {
	--crust: #d17d38;
	--crust-dark1: #b6692b;
	--crust-dark2: #955623;

	--tan: #f4d1a4;
	--tan-dark1: #efbf80;
	--tan-dark2: #ebad5c;

	--dough: #fff9ee;
	--dough-dark1: #fff1d6;
	--dough-dark2: #ffe4ad;

	--jet: #292929;
	--jet-dark1: #141414;
	--jet-dark2: #000000;
}
