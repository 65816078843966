.icon-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.8rem;
	width: 1.8rem;
	border: 0.2rem solid var(--jet);
	border-radius: 3px;
	background-color: var(--dough);
	transition: background-color 150ms ease;

	&__image {
		height: 0.9rem;
		width: 0.9rem;
	}

	&--medium {
		height: 2.6rem;
		width: 2.6rem;

		& .icon-button__image {
			height: 1.6rem;
			width: 1.6rem;
		}
	}

	&:hover {
		background-color: var(--dough-dark1);
	}

	&:active {
		background-color: var(--dough-dark2);
	}
}
