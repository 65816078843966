.text-input {
	width: 100%;
	height: 2.6rem;
	padding-inline: 0.5rem;
	background-color: var(--tan);
	border: 0.2rem solid var(--jet);
	border-radius: 3px;
	font-size: 1rem;
	transition: background-color 150ms ease;

	&:hover {
		background-color: var(--tan-dark1);
	}

	&:active {
		background-color: var(--tan-dark2);
	}

	&::placeholder {
		color: var(--jet);
		opacity: 1;
	}

	&:focus {
		outline: none;
		background-color: var(--tan-dark2);

		&::placeholder {
			opacity: 0;
		}
	}

	&--dough {
		background-color: var(--dough);

		&:hover {
			background-color: var(--dough-dark1);
		}

		&:active {
			background-color: var(--dough-dark2);
		}

		&:focus {
			background-color: var(--dough-dark2);
		}
	}
}
